.modal-overlay-full {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-full {
  background: #fff;
  width: 80%;
  padding: 20px;
  border-radius: 8px;
  max-height: 80%;
  overflow-y: auto;
  text-align: left;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content h2 {
  margin-top: 0;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.details-left {
  display: flex;
  flex-direction: column;
}

.details-right {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.details-left div,
.details-right div {
  margin-bottom: 8px;
}

.description {
  margin-top: 20px;
}
