.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 30%;
    max-height: 70%;
    overflow-y: auto;
  }
  
  .modal-content h2 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .modal-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .modal-list-item {
    padding: 10px;
    font-size: 1.2rem;
    color: #444;
    border-bottom: 1px solid #ccc;
  }
  
  .modal-list-item:hover {
    background: #f5f5f5;
    cursor: pointer;
  }
  
  .modal-close-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-close-btn:hover {
    background-color: #0056b3;
  }
  