.list-item {
  margin: 10px 0;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
}

.list-item:hover {
  background-color: #e3e3e3; /* Zmiana koloru tła na jaśniejszy */
  transform: scale(1.02); /* Delikatne powiększenie na hover */
  border-color: #bbb; /* Zmiana koloru obramowania */
}

.list-item-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.list-item h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.list-item-meta {
  font-size: 12px;
  color: #999;
  text-transform: lowercase;
}

.list-item-date {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.list-item-description {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  line-height: 1.6;
}
