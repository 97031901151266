.App {
  font-family: Arial, sans-serif;
  color: black;
  background-color: white;
  margin: 0;
  padding: 0;
}

.main {
  padding: 20px;
}
