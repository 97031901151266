.header {
  display: flex;
  justify-content: space-between; /* Rozdziela elementy na boki */
  align-items: center; /* Wycentrowanie w pionie */
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.header-logo {
  text-decoration: none; /* Usuń podkreślenie linku */
  color: #007bff; /* Niebieski kolor dla linku */
  font-weight: bold; /* Wyróżnienie tekstu */
  font-size: 20px; /* Większa czcionka dla lepszej widoczności */
}

.header-logo:hover {
  color: #0056b3; /* Ciemniejszy niebieski przy najechaniu */
  text-decoration: underline; /* Podkreślenie przy najechaniu */
}

/* Styl dla modala */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Półprzezroczyste tło */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Na wierzchu */
}

.modal-content {
  background: #fff; /* Białe tło */
  padding: 20px 30px;
  border-radius: 8px; /* Zaokrąglone rogi */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Lekki cień */
  width: 90%;
  max-width: 250px; /* Maksymalna szerokość */
}

.modal-content h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
  text-align: center;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.modal-content button {
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.modal-content button:hover {
  background: #0056b3;
}

.modal-content button:last-child {
  background: #e0e0e0;
  color: #333;
}

.modal-content button:last-child:hover {
  background: #cfcfcf;
}


.header button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: #007bff; /* Niebieski */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.header button:hover {
  background-color: #0056b3; /* Ciemniejszy niebieski */
}

.header button:disabled {
  background-color: #c0c0c0; /* Szary, gdy wyłączony */
  cursor: not-allowed;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}