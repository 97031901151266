.main-content {
  /* padding: 20px; */
  padding: 0px 20px 20px 20px;
  text-align: center;
}


.list {
  margin-top: 20px;
}
