.main-container {
  display: flex;
}

.side-column {
  width: 250px;
  /* background-color: #f8f8f8; */
}

.content-column {
  flex: 1;
  /* padding: 20px; */
  text-align: center;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
