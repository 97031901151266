
.opheader h2 {
    text-decoration: underline;
  }
  
  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  .filter-item {
    cursor: pointer;
    font-size: 1.2rem;
    color: #007bff;
    text-decoration: underline;
  }
  
  .filter-item:hover {
    color: #0056b3;
  }