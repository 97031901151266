.main-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;  
  }

  .service-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 kolumny */
    gap: 20px; /* Odstęp między ikonami */
    margin: 30px auto;
    max-width: 800px; /* Maksymalna szerokość siatki */
  }
  
  .service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    font-size: 1.5rem; /* Rozmiar ikony */
    text-decoration: none; /* Usunięcie domyślnego podkreślenia */
    color: #333;
  }
  
  .service-item:hover {
    transform: scale(1.1); /* Powiększenie przy najechaniu */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  .service-item span {
    margin-top: 10px; /* Odstęp między ikoną a tekstem */
    font-size: 1rem; /* Rozmiar opisu */
    color: #333;
  }