.footer {
  padding: 10px 20px;
  /* background-color: #f0f0f0; */
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.footer-links {
  margin: 5px;
}

.footer-links a {
  margin: 0 20px;
  color: #555;
  text-decoration: none;
  font-weight: bold;
}

.footer-links a:hover {
  color: #333;
}
